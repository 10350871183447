<template>
  <el-main>
    <el-row type="flex" justify="space-between">
      <el-col :span="12">
        <BreadCrumb />
      </el-col>
      <el-col :span="12" class="btn-group">
        <el-button @click="deleteLogs"><i class="el-icon-delete"></i> Clear</el-button>
      </el-col>
    </el-row>
    <el-table stripe @sort-change="handleColumn" style="margin-top: 10px" border v-loading="loading" :data="list" :sort="sort">
      <el-table-column prop="createdAt" label="Date Time" sortable="custom">
        <template slot-scope="scope">
          {{ getDate(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="action" label="Action">
      </el-table-column>
      <el-table-column prop="ip" label="IP Address">
      </el-table-column>
      <el-table-column prop="state" label="State">
        <template slot-scope="scope">
          {{ scope.row.state ? stateMap[scope.row.state] : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="method" label="Method">
      </el-table-column>
      <el-table-column prop="data" label="Data"
        width="500">
        <template slot-scope="scope">
          {{ JSON.stringify(scope.row.data) }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="Actions">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="viewLog(scope.$index, scope.row)"><i class="el-icon-folder-opened"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, total"
      :total="total">
    </el-pagination>
    <LogDetailsDialog ref="LogDetailsDialog" :log="log" :dialogFormVisible.sync="dialogFormVisible"></LogDetailsDialog>
  </el-main>
</template>
<script>
import BreadCrumb from '../components/BreadCrumb.vue'
import { mapState } from 'vuex'
import LogDetailsDialog from '../components/LogDetailsDialog.vue'
import moment from 'moment'
export default {
  components: { BreadCrumb, LogDetailsDialog },
  data () {
    const query = this.$route.query
    return {
      loading: false,
      stateMap: {
        received: 'Received',
        success: 'Success',
        failed: 'Failure'
      },
      list: [],
      total: 0,
      log: {},
      dialogFormVisible: false,
      sort: {
        prop: query.sort || 'createdAt',
        order: query.order || 'descending'
      },
      search: query.searchStr ? Number(query.searchStr) : '',
      pageSize: query.pageSize ? Number(query.pageSize) : 10,
      currentPage: query.currentPage ? Number(query.currentPage) : 1,
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: async function () {
    this.getList()
  },
  methods: {
    getDate (createdAt) {
      return moment(createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    viewLog (index, row) {
      this.log = row
      this.dialogFormVisible = true
    },
    async deleteLogs () {
      try {
        await this.$store.httpDispatch({})('deleteLogs')
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        return false
      }
      this.getList()
      this.$message({
        type: 'success',
        message: 'Cleaned up successfully!'
      })
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleColumn (column) {
      this.sort = column
      this.getList()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getList()
    },
    async getList () {
      this.loading = true
      let result = {}
      let query = {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        searchStr: this.search,
        sort: this.sort.prop,
        order: this.sort.order
      }
      try {
        result = await this.$store.httpDispatch({})('getLogsList', query)
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.loading = false
        return false
      }
      const { code, data } = result.data
      if (code === 0) {
        data.logs.forEach((log) => {
           delete log.data._now_
         })
        this.list = data.logs
        this.total = data.total
      }
      this.loading = false
    }
  }
}
</script>
<style>
</style>
