

<template>
  <div>
    <el-dialog
      title="Log Details"
      class="log-dialog"
      @close="closeDialog"
      :visible="dialogFormVisible"
      width="800px"
      center>
      <el-form label-width="100px" label-suffix=":">
        <el-form-item label="Date Time" >
          <div>{{getDate(log.createdAt)}}</div>
        </el-form-item>
        <el-form-item label="Action">
          <div>{{log.action}}</div>
        </el-form-item>
        <el-form-item label="IP Address">
          <div>{{log.ip}}</div>
        </el-form-item>
        <el-form-item label="Data">
          <div>
            <el-table
              border
              :data="tableData"
              height="250"
              stripe
              style="width: 100%">
              <el-table-column
                prop="key"
                label="Key"
                width="180">
              </el-table-column>
              <el-table-column
                prop="value"
                label="Value">
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item class="dialog-btn">
          <el-button @click="closeDialog">OK</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['dialogFormVisible', 'log'],
  data() {
    return  {
    }
  },
  computed: {
    tableData: function () {
      if (!this.log.data) return []
      return Object.keys(this.log.data).map((key) => {
        if (key!== '_now_') {
          return { 
            key: key,
            value: this.log.data[key].toString()
          }
        }
      })
    }
  },
  methods: {
    getDate (createdAt) {
      return moment(createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    closeDialog () {
      console.log(this.log)
      this.$emit('update:dialogFormVisible', false)
    },
    submitForm(formName) {
    }
  }
}
</script>
<style lang="less">
.log-dialog {
  .dialog-btn {
    text-align: right;
    .el-button {
      width: 120px;
    }
  }
}
</style>
